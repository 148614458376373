import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-02";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-02";
import ContactArea from "@containers/contact/layout-02";
import { Container } from "react-bootstrap";

const ProductEnginerring = ({ pageContext, location, data }) => {
    const content = normalizedData(data?.page?.content || []);
    const globalContent = normalizedData(data?.allGeneral.nodes || []);

    return (
        <Layout location={location}>
            <Seo titleTemplate="TEQT" title="Metaverse"
                description="We have successfully completed $40,000,000 worth of digital marketing projects for more than 5,000 clients in more than 120 countries." />
            <Header
                data={{
                    ...globalContent["header"],
                    ...globalContent["menu"],
                    socials: data.site.siteMetadata.socials,
                }}
            />
            <main className="site-wrapper-reveal">
                <PageHeader
                    pageContext={pageContext}
                    location={location}
                    title="IT outsourcing company"
                />
                <div className="service-container">
                    <Container>
                        <h6 className="mt-5">Engineering Led Outcomes</h6>
                        <p className="mt-2">Our Engineering strategy is deep-rooted in the business outcomes we deliver and the culture of trust we have with our clients.</p>
                        <p>We have well rounded expertise in product engineering:</p>

                        <ul className="circle-list">
                            <li className="mt-2"><span className="text-black"> Client products:</span> custom building products jointly with our clients</li>
                            <li className="mt-2"><span className="text-black"> TEQT products:</span> innovating to build our own products.</li>
                        </ul>
                        <p>Product Engineering offers services that are infused by a Product mindset with an adaptable Operating Model and multi-velocity Business Model with emphasis on three distinct and differentiated services –</p>

                        <ul className="circle-list">
                            <li className="mt-2 ">Product Mindset for innovations and speed</li>
                            <li className="mt-2">
                                Cloud Engineering for scalability and elasticity</li>
                            <li className="mt-2">
                                Process Engineering for optimization and modernization across Business & IT landscape.</li>
                        </ul>

                        <h6 className="mt-3">Our Differentiated Approach</h6>
                        <ul className="circle-list mt-3">
                            <li className="mt-2"><span className="text-black"> Product Engineering – </span>Our product engineering services focus on product design & innovation, development, and product management services. The product design and innovation services use our proprietary frameworks and workshops themed around Design Thinking, LEAN Startup, Minimum Viable Product to prove the concept, build a prototype and lay the foundation of design and development. The development services typically use Global AGILE methodology to drive the SDLC and help clients rapidly develop with Speed, Scale and Certainty and realize their product vision.</li>
                            <li className="mt-2"><span className="text-black">Cloud Engineering –</span> We help our clients define strategy, build and operate the digital backbone using hybrid and multi-cloud. The Cloud Engineering services include helping clients understand the digital assets and their disposition to moving to Cloud. Our services also include the development of cloud-native architectures and applications leveraging cloud services from any of the Hyperscalers, i.e., Azure, AWS and GCP.</li>
                            <li className="mt-2"><span className="text-black"> Design thinking:</span> TEQT uses Design thinking approach to metaverse development, combining technical expertise with user-centered design principles to create virtual experiences that are engaging, accessible, and easy to use.</li>
                            <li className="mt-2 mb-5"><span className="text-black"> Process Engineering –</span> Our process engineering services primarily include cognitive & automation services. These services cover the spectrum from assisted automation to un-assisted automation, to intelligent automation and to more cognitive services using Vision AI, Speech AI, Document AI, and Graph AI services.</li>
                        </ul>
                    </Container>
                </div>
                <ContactArea data={content["contact-section"]} />
            </main>
            <Footer data={{ ...data.site.siteMetadata }} />
        </Layout>
    );
};

export const query = graphql`
    query ProductEnginerringPageQuery {
        allGeneral {
            nodes {
                section
                ...HeaderTwo
            }
        }
        site {
            ...Site
        }
        page(title: { eq: "it-solutions" }, pageType: { eq: "innerpage" }) {
            content {
                ...PageContent
            }
        }
        allItSolution {
            nodes {
                ...ItSolutionThree
            }
        }
    }
`;

ProductEnginerring.propTypes = {
    pageContext: PropTypes.shape({}),
    location: PropTypes.shape({}),
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                contact: PropTypes.shape({}),
            }),
        }),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allItSolution: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export default ProductEnginerring;
